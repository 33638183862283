import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse, ICity } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  type?: "active" | "inactive";
}

export const useGetCitiesList = (params?: IParams) =>
  useQuery<void, APIErrorResponse, { data: ICity[] }>({
    queryKey: ["get-cities-list", params?.type],
    queryFn: async () => {
      return apiClient.get(`/city`, { params: params ?? {} });
    },
    gcTime: 0,
  });

export const useUpdateCitiesMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    {
      id: string;
      isActive: boolean;
    }[]
  >({
    mutationFn: async (data) => {
      return apiClient.put(`/city`, data);
    },
  });
