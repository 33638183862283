import React, { useRef, useEffect, useState, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

interface IProps {
  clickedCoordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  setClickedCoordinates: Function;
}

const defaultCoordinates = { lat: 24.708630647417472, lng: 46.687259674072266 };

const GoogleMapWithMUITextField: React.FC<IProps> = ({
  clickedCoordinates,
  setClickedCoordinates,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerPosition, setMarkerPosition] = useState(
    clickedCoordinates || defaultCoordinates
  );

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setClickedCoordinates({ lat, lng });
      // setMarkerPosition({ lat, lng });
    }
  };

  const setupAutocomplete = useCallback(() => {
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Can customize types of results
          componentRestrictions: { country: "SA" }, // Restrict to specific countries if needed
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry?.location) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          setMarkerPosition(location);
          setClickedCoordinates(location);
          if (map) {
            map.panTo(location);
          }
        }
      });

      autocompleteRef.current = autocomplete;
    }
  }, [map]);

  useEffect(() => {
    setupAutocomplete();
  }, [setupAutocomplete]);

  useEffect(() => {
    setMarkerPosition(clickedCoordinates || defaultCoordinates);
  }, [clickedCoordinates]);

  console.log({ markerPosition, clickedCoordinates });

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBjGZRBq1seDBmbq62CMyV2VkpRrOwNh_Q"
      libraries={["places"]}
    >
      <Box position={"relative"}>
        <TextField
          variant="outlined"
          fullWidth
          inputRef={inputRef}
          placeholder="Search"
          sx={{
            position: "absolute",
            top: "10px",
            left: "190px",
            zIndex: 1,
            bgcolor: "#1A1A1A",
            maxWidth: "300px",
            overflow: "hidden",
          }}
          size="small"
        />
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={markerPosition}
          zoom={12}
          onLoad={onMapLoad}
          onClick={onMapClick}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      </Box>
    </LoadScript>
  );
};

export default GoogleMapWithMUITextField;
