import { useEffect } from "react";
import { Grid } from "@mui/material";
import ServiceExperience from "./service-experience";
import EmployeeExperience from "./employee-experience";

interface IProps {
  cities: string[];
  locations: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  rates: string[];
}

const ExperienceStatistics = (props: IProps) => {
  const { cities, dateRange, rates, locations } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <ServiceExperience
          cities={cities}
          locations={locations}
          dateRange={dateRange}
          rates={rates}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <EmployeeExperience
          cities={cities}
          locations={locations}
          dateRange={dateRange}
          rates={rates}
        />
      </Grid>
    </Grid>
  );
};

export default ExperienceStatistics;
