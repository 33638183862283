import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TicketsStatistics from "./tickets-statistics";
import Payments from "./payments";
import PaymentMethod from "./payment-method";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useMemo, useState } from "react";
import { onSnapshot, query, collection } from "firebase/firestore";
import { db, functions } from "src/firebase";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import { httpsCallable } from "firebase/functions";
import { ITransReportsData } from "src/types/business-intelligence";
import { useGetLocationsList } from "src/api/locations";
import { useGetCitiesList } from "src/api/general";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BITransactionsPage = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("year").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [tempSelectedLocations, setTempSelectedLocations] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [selectedCities, setSelectedCities] = useState<any[]>([]);
  const [data, setData] = useState<ITransReportsData>();
  const [isLoading, setIsLoading] = useState(true);

  //Callable functions
  const fetchReportsTransData = httpsCallable(functions, "combinedReports");

  // APIS
  const { data: locationsData } = useGetLocationsList();
  const { data: citiesData } = useGetCitiesList({ type: "active" });

  useEffect(() => {
    setIsLoading(true);
    fetchReportsTransData({
      ...(selectedLocations.length > 0 && {
        locations: selectedLocations.map((loc) => loc.nameEn),
      }),
      ...(selectedClients.length > 0 && {
        clients: selectedClients.map((client) => client.name),
      }),
      ...(selectedCities.length > 0 && {
        cities: selectedCities.map((city) => city.nameEn),
      }),
      from: dateRange.startDate || undefined,
      to: dateRange.endDate || undefined,
    })
      .then((result) => {
        setData(result.data as ITransReportsData);
        setIsLoading(false);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
      });
  }, [
    selectedLocations,
    selectedClients,
    selectedCities,
    dateRange.startDate,
    dateRange.endDate,
  ]);

  const handleChangeClients = (value: any[]) => {
    setSelectedClients(value);
  };

  const handleChangeLocations = (value: any[], reason: string) => {
    setTempSelectedLocations(value);

    if (reason === "clear") {
      setSelectedLocations([]); // Clear the input value
    }
  };

  const handleChangeCities = (value: any[]) => {
    setSelectedCities(value);
  };

  // Fetching filters data
  useEffect(() => {
    let unsubscribeClients: () => void;

    unsubscribeClients = onSnapshot(
      query(collection(db, "clients")),
      (QuerySnapshot) => {
        const fetchedClients: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedClients.push({ ...doc.data(), id: doc.id });
        });
        // Sort clients alphabetically
        fetchedClients.sort((a, b) => a.name?.localeCompare(b.name));
        setClients(fetchedClients);
      }
    );

    return () => {
      if (unsubscribeClients) {
        unsubscribeClients();
      }
    };
  }, []);

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations.sort((a: any, b: any) =>
        a.nameEn?.localeCompare(b.nameEn)
      ) || []
    );
  }, [locationsData]);

  return (
    <Box p={{ xs: "12px", lg: "30px" }}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Transactions Report
      </Typography>
      <Stack direction={"row"} gap={"10px"} flexWrap={"wrap"} mb={3}>
        {/* cities */}
        <Autocomplete
          sx={{ width: { xs: "100%", md: "17%" } }}
          size="small"
          limitTags={1}
          multiple
          id="tags-outlined"
          options={citiesData?.data || []}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.nameEn}
          renderOption={(props, option, { selected }) => (
            <li key={props.id} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.nameEn}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="City" placeholder="" />
          )}
          onChange={(event, value) => {
            handleChangeCities(value);
          }}
        />
        {/* locations */}
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "17%" } }}
          size="small"
          limitTags={1}
          multiple
          id="tags-outlined"
          options={sortedLocations}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.nameEn}
          renderOption={(props, option, { selected }) => (
            <li key={`${props.id} ${option.id}`} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.nameEn}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Location" placeholder="" />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option: any, index: number) => (
              <Chip
                size="small"
                label={option.nameEn}
                {...getTagProps({ index })}
                key={option.id}
                onDelete={undefined} // Disable the "x" delete icon
              />
            ))
          }
          onChange={(event, value, reason) => {
            handleChangeLocations(value, reason);
          }}
          onClose={() => {
            setSelectedLocations(tempSelectedLocations);
          }}
        />
        {/* clients  */}
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "17%" } }}
          size="small"
          limitTags={1}
          multiple
          id="tags-outlined"
          options={clients}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option, { selected }) => (
            <li key={props.id} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.name}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Client" placeholder="" />
          )}
          onChange={(event, value) => {
            handleChangeClients(value);
          }}
        />
        {/* date range */}
        <Box sx={{ minWidth: 183 }}>
          <DateRangeInput
            startDate={dateRange.startDate ? moment(dateRange.startDate) : null} // moment.Moment | null
            startDateId={`your_unique_start_date_id`}
            endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
            endDateId={`your_unique_end_date_id`}
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setDateRange({
                startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                endDate: arg.endDate?.format("yyyy-MM-DD") as string,
              });
            }}
            showClearDates
          />
        </Box>
      </Stack>
      {isLoading ? (
        <Box display={"flex"} justifyContent={"center"} pt={30}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TicketsStatistics data={data?.ticketReports} />
          <Box mt={2} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Payments data={data?.incomeReports} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PaymentMethod data={data?.paymentMethodReports} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default BITransactionsPage;
