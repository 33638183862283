import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";
import { ILocation } from "src/types/locations";

interface IParams {
  type?: "active" | "archived";
}

export const useGetLocationsList = (params?: IParams) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-locations-list", params?.type],
    queryFn: async () => {
      return apiClient.get(`/location`, { params: params ?? {} });
    },
    gcTime: 0,
  });

export const useAddLocationMutation = () =>
  useMutation<any, APIErrorResponse, ILocation>({
    mutationFn: async (data) => {
      return apiClient.post(`/location`, data);
    },
  });

export const useUpdateLocationMutation = () =>
  useMutation<any, APIErrorResponse, ILocation>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/location/${id}`, rest);
    },
  });

export const useGetLocationById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, ILocation>({
    queryKey: ["get-location-by-id"],
    queryFn: async () => {
      return apiClient.get(`/location/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useArchiveLocationMutation = () =>
  useMutation<any, APIErrorResponse, string>({
    mutationFn: async (locationId) => {
      return apiClient.put(`/location/${locationId}/archive`, locationId);
    },
  });

export const useActivateLocationMutation = () =>
  useMutation<any, APIErrorResponse, string>({
    mutationFn: async (locationId) => {
      return apiClient.put(`/location/${locationId}/unarchive`, locationId);
    },
  });
