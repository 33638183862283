import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const createCustomTheme = () => {
  return createTheme({
    typography: {
      fontFamily: "Inter, sans-serif",
      fontSize: 13,
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#CC9C33",
        contrastText: "#ffffff",
      },
      secondary: {
        // main: "#313133",
        main: "#3F4254",
      },
      tertiary: {
        // main: "#0E0E0E",
        main: "#303030",
      },
      text: {
        primary: "#F1F1F1",
        secondary: "#CC9C33",
      },
      background: {
        // default: "#242424",
        // paper:"#242424"
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            // borderRadius: "8px",
            // fontWeight: 600,
            textTransform: "unset",
          },
        },
        variants: [
          {
            props: { size: "large" }, // The button for large size
            style: {
              height: "50px",
              fontSize: "15px",
            },
          },
          {
            props: { size: "medium" },
            style: {
              height: "38px",
              fontSize: "14px",
            },
          },
          {
            props: { size: "small" },
            style: {
              height: "32px",
              fontSize: "14px",
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            display: "block",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#212121", // Set your desired background color
            backgroundImage: "unset",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: "1px solid #5B5B5B",
            borderRadius: "10px",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "&::-webkit-scrollbar": {
              width: "9px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F", // Match dark mode background
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D", // Match component styling
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59", // Highlight on hover
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },
      // MuiInputBase: {
      //   styleOverrides: {
      //     input: {
      //       // fontSize: "13px", // Set the font size for all input elements
      //     },
      //   },
      // },
      // MuiOutlinedInput: {
      // styleOverrides: {
      //   root: {
      //     height: "48px", // Set the height for all "medium" size TextFields
      //   },
      //   input: {
      //     padding: "0 14px", // Adjust the padding for proper alignment within the defined height
      //     height: "48px", // Ensure the input field fills the height
      //     // display: 'flex',
      //     // alignItems: 'center', // Align text vertically
      //   },
      // },

      // variants: [
      //   {
      //     props: { size: "medium" }, // The button for large size
      //     style: {
      //       height: "48px",
      //     },
      //   },
      // ],
      // },
      // MuiSelect: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#313133',
      //       color:'#D0D0D0'
      //     },
      //     icon: {
      //       color: '#D0D0D0', // Arrow color
      //     },
      //   },
      // },
    },
  });
};

export default createCustomTheme;
