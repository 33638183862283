import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "src/assets/svg-icons/close.svg";
import CarIcon from "src/assets/svg-icons/car.svg";
import PersonIcon from "src/assets/svg-icons/person.svg";
import InfoItem from "./info-item";

interface IProps {
  open: boolean;
  onClose: any;
  transDetails: any;
}

const TicketInfoPopup = ({ open, onClose, transDetails }: IProps) => {
  console.log({ transDetails });

  const {
    carColor,
    carModel,
    checkInDate,
    checkInTime,
    checkOutDate,
    checkOutTime,
    customerMobileNumber,
    customerName,
    parkingFees,
    paymentMethod,
    plateNum,
    service,
    ticketNum,
    involvedEmployees,
  } = transDetails?.transactionDetails || {};

  const checkInEmployee = involvedEmployees?.find(
    (item: any) => item?.operation === "checkin"
  );
  const checkOutEmployee = involvedEmployees?.find(
    (item: any) => item?.operation === "checkout"
  );

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box minWidth={{ xs: "100vw", md: "500px" }} padding={"40px 30px"}>
        {/* header */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #454343"}
          paddingBottom={"29px"}
        >
          <Typography>Transaction Details</Typography>
          <IconButton sx={{ p: "0" }} onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </IconButton>
        </Stack>
        {/* content */}
        <Box paddingY={"26px"}>
          <Stack gap={2.5}>
            {/* ticket details */}
            <InfoItem label="#Ticket-Num" value={ticketNum} />
            <InfoItem label="Location" value={transDetails.locationName} />
            <InfoItem label="Service" value={service} />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box flexBasis={"50%"}>
                <InfoItem label="Parking fees" value={parkingFees || "--"} />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem
                  label="Payment Method"
                  value={paymentMethod || "--"}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box flexBasis={"50%"}>
                <InfoItem label="Check in Date" value={checkInDate || "--"} />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem label="Check out Date" value={checkOutDate || "--"} />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between" || "--"}>
              <Box flexBasis={"50%"}>
                <InfoItem label="Check in Time" value={checkInTime || "--"} />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem label="Check out Time" value={checkOutTime || "--"} />
              </Box>
            </Stack>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* car details */}
            <Stack direction={"row"} gap={1}>
              <img src={CarIcon} alt="" />
              <Typography>Car Details</Typography>
            </Stack>
            <InfoItem label="#Plate-Num" value={plateNum || "--"} />
            <Stack direction={"row"} justifyContent={"space-between"} gap={1}>
              <Box flexBasis={"50%"}>
                <InfoItem label="Model" value={carModel || "--"} />
              </Box>
              <Box flexBasis={"50%"}>
                <InfoItem label="Color" value={carColor || "--"} />
              </Box>
            </Stack>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* customer details */}
            <Stack direction={"row"} gap={1}>
              <img src={PersonIcon} alt="" />
              <Typography>Customer Details</Typography>
            </Stack>
            <InfoItem label="Name" value={customerName || "--"} />
            <InfoItem
              label="Mobile Number"
              value={customerMobileNumber || "--"}
            />
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            <InfoItem
              label="Check in Employee"
              value={checkInEmployee?.userName || "--"}
            />
            <InfoItem
              label="Check out Employee"
              value={checkOutEmployee?.userName || "--"}
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default TicketInfoPopup;
