import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { genericErrorHandler } from "src/utils/_genericErrorHandler";
import { authentication } from '../firebase';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

// Axios interceptor to inject the token to requests
axios.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
  const currentUser = authentication.currentUser;

  if (currentUser) {
    try {
      const token = await currentUser.getIdToken(); 
      request.headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }
  
  return request;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle errors globally using the provided genericErrorHandler
    genericErrorHandler(error);
    return Promise.reject(error.response);
  }
);

const apiClient = axios;

export default apiClient;
