import { Menu, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { authState } from "src/store/auth";
import AddEditServicePopup, {
  ServiceFormInputs,
} from "../../add-edit-service-popup";
import ConfirmPopup from "src/shared/components/confirmation-popup";

const TableActions = ({
  index,
  setServices,
  row,
  services,
}: {
  index: number;
  setServices: Function;
  services: ServiceFormInputs[];
  row: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setServices((prev: any) => {
      return prev.filter((_: any, i: number) => i !== index);
    });
  };

  const handleOpenEdit = () => {
    setAnchorEl(null);
    setOpenEdit(true);
  };

  const onClose = () => {
    setOpenEdit(false);
  };

  const handleOpenDeletePopup = () => {
    setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  return (
    <Stack direction={"row"}>
      {permissions?.arsannEmployees.edit && (
        <>
          <LoadingButton
            variant="contained"
            color="inherit"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}
            loading={isLoading}
            size="small"
            sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
          >
            Actions
          </LoadingButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleOpenEdit}
              sx={{ width: "105px", color: "primary.main" }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={handleOpenDeletePopup}>Delete</MenuItem>
          </Menu>
        </>
      )}
      <AddEditServicePopup
        open={openEdit}
        onClose={onClose}
        setServices={setServices}
        serviceDataToEdit={row}
        serviceDataToEditIndex={index}
        services={services}
      />
      <ConfirmPopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        handleClose={handleCloseDeletePopup}
        subtitle="This action will remove this service"
        submitBtnLabel="Delete"
        onSave={handleDelete}
      />
    </Stack>
  );
};
export default TableActions;
