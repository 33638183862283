import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { memo, useEffect, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Button,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useFetchCustomersFeedbackMutation } from "src/api/customers/feedback";
import CommentCell from "../comment-cell";
import moment from "moment";
import TicketInfoPopup from "../ticket-info-popup";

const tableHeadText = [
  "Date",
  "Location",
  "Customer Name",
  "Comment",
  "Rate",
  "Action",
];

interface IProps {
  cities: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  rates: string[];
  locations: string[];
}

const EmployeeCommentsTable = (props: IProps) => {
  const { cities, locations, dateRange, rates } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState();

  const {
    mutate,
    data: servicedFeedbackData,
    isPending,
  } = useFetchCustomersFeedbackMutation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setClickedRow(undefined);
  };

  useEffect(() => {
    mutate({
      type: "employee",
      page: page + 1,
      limit: rowsPerPage,
      ...(cities.length > 0 ? { cityIds: cities } : {}),
      ...(locations.length > 0 ? { locationIds: locations } : {}),
      ...(dateRange.startDate && {
        startDate: moment(dateRange.startDate).format("DD/MM/YYYY"),
      }),
      ...(dateRange.endDate && {
        endDate: moment(dateRange.endDate).format("DD/MM/YYYY"),
      }),
      ...(rates.length > 0 ? { ratings: rates.map((rate) => +rate) } : {}),
    });
  }, [page, rowsPerPage, cities, locations, dateRange]);

  useEffect(() => {
    if (!!servicedFeedbackData) {
      setTotalCount(servicedFeedbackData.data.totalCount as number);
    }
  }, [servicedFeedbackData]);

  const emptyDataArr =
    servicedFeedbackData?.data.totalCount === 0 && !isPending;

  return (
    <Paper
      sx={{
        bgcolor: "#242424",
        boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
        padding: { xs: "15px", md: "30px" },
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography fontSize={"20px"} fontWeight={700}>
          Employee Experience Comments
        </Typography>
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#CC9C33",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!servicedFeedbackData ? (
            <TableLoadingSkeleton rowsLength={rowsPerPage} cellsLength={6} />
          ) : (
            <TableBody>
              {servicedFeedbackData.data.feedbacks.map((row: any) => {
                return (
                  <TableRow
                    key={row.ticketId}
                    sx={{
                      "&:last-child td, &:last-child th": {},
                    }}
                  >
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell>{row.locationName}</TableCell>
                    <TableCell>{row.transactionDetails?.customerName}</TableCell>
                    <TableCell>
                      <CommentCell text={row.comment} />
                    </TableCell>
                    <TableCell>{row.rate}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setOpen(true);
                          setClickedRow(row);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>

        {emptyDataArr && <NoData />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {open && !!clickedRow ? (
        <TicketInfoPopup
          transDetails={clickedRow}
          open={open}
          onClose={handleCloseDrawer}
        />
      ) : null}
    </Paper>
  );
};
export default memo(EmployeeCommentsTable);
