import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { ServiceFormInputs } from "../add-edit-service-popup";

const tableHeadText = [
  "Service Type",
  "Service Name",
  "Pricing Model",
  "Payment",
  "Actions",
];

const ServicesTable = ({
  services,
  setServices,
}: {
  services: ServiceFormInputs[];
  setServices: Function;
}) => {
  return (
    <TableContainer
      sx={{
        boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
        padding: "30px",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeadText.map((item) => (
              <TableCell
                key={item}
                sx={{
                  fontWeight: 600,
                  color: "#A1A5B7",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {false ? (
          <TableLoadingSkeleton rowsLength={7} cellsLength={6} />
        ) : (
          <TableBody>
            {services?.map((row, index) => (
              <TableRow
                key={`${row.name}_${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {row.type === "valet" ? "Valet" : "Self Parking"}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {row.pricingType === "free"
                    ? "Free"
                    : row.pricingType === "fixed"
                    ? "Fixed Price"
                    : row.pricingType === "hourly"
                    ? "Hours - based price"
                    : "Custom Price"}
                </TableCell>

                <TableCell>
                  {row.payment === "entry"
                    ? "At Entry"
                    : row.payment === "exit"
                    ? "At Exit"
                    : row.pricingType === "free"
                    ? "Free"
                    : "Flexible"}
                </TableCell>
                <TableCell>
                  <TableActions
                    index={index}
                    setServices={setServices}
                    services={services}
                    row={row}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
export default ServicesTable;
