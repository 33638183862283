import { Box, Stack, Typography } from "@mui/material";
import SmallStarIcon from "src/assets/svg-icons/small-star.svg";

interface IProps {
  stars: string;
  percentage: string;
  review: string;
}
const Bar = (props: IProps) => {
  const { stars, percentage, review } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
    >
      <Stack direction={"row"} spacing={"5px"} alignItems={"center"}>
        <img src={SmallStarIcon} alt="" width={"11px"} height={"10px"} />
        <Typography fontSize={"16px"} color={"#FFFFFF"}>
          {stars}
        </Typography>
      </Stack>
      <Box
        bgcolor={"#3F3F3F"}
        borderRadius={"12px"}
        boxShadow={" 0px 2.232px 11.161px 0px rgba(0, 0, 0, 0.10)"}
        width={"75%"}
        height={"16px"}
        overflow={"hidden"}
      >
        <Box
          bgcolor={"#D4AD53"}
          width={`${percentage}%`}
          height={"16px"}
          borderRadius={"12px 0 0 12px"}
        />
      </Box>
      <Typography color={"#FFFFFF"} fontSize={"14px"} whiteSpace={"nowrap"}>
        {review || 0} review
      </Typography>
    </Box>
  );
};

export default Bar;
