import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  location: string;
  paymentTypes?: string[];
  serviceTypes?: string[];
  service?: string; //"valet" | "selfParking"
  from?: string;
  to?: string;
  search?: string;
  limit: string;
  offset: string;
}

export const useGetTransactionsList = (params: IParams) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: [
      "get-transactions-list",
      params?.from,
      params?.to,
      params?.location,
      params?.service,
      params?.limit,
      params?.offset,
      ...(params?.paymentTypes || []),
      params.search,
    ],
    queryFn: async () => {
      return apiClient.get(`/transaction`, { params });
    },
    gcTime: 0,
    enabled: !!params?.from && !!params.to && !!params.location,
  });
