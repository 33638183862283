import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import { useActivateLocationMutation } from "src/api/locations";
import { authState } from "src/store/auth";

const TableActions = ({
  rowId,
  refetch,
}: {
  rowId: string;
  refetch: Function;
}) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // APIS
  const { mutate, status, error, isPending } = useActivateLocationMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleActivate = () => {
    handleClose();
    mutate(rowId);
  };

  const handleNavigateToEditPage = () => {
    navigate(`/locations/archived/${rowId}/edit`);
  };

  //Archive success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "Activated location successfully",
        },
      ]);
      handleClosePopup();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"}>
      {permissions?.locations.edit ? (
        <LoadingButton
          variant="contained"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          size="small"
          sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
        >
          Actions
        </LoadingButton>
      ) : null}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleNavigateToEditPage}
          sx={{ width: "105px", color: "primary.main" }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenPopup}>Activate</MenuItem>
      </Menu>

      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="This action will remove this location from archived list and add it to
        active list"
        submitBtnLabel="Activate"
        onSave={handleActivate}
        isLoading={isPending}
      />
    </Stack>
  );
};
export default TableActions;
