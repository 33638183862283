import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  type?: "employee" | "service";
  page?: number;
  limit?: number;
  cityIds?: string[];
  locationIds?: string[];
  ratings?: number[];
  startDate?: string;
  endDate?: string;
}

export const useFetchCustomersFeedbackMutation = () =>
  useMutation<any, APIErrorResponse, IParams>({
    mutationFn: async (data) => {
      return apiClient.post(`/feedback/list`, data);
    },
  });

export const useFetchFeedbackAvgRatingMutation = () =>
  useMutation<any, APIErrorResponse, IParams>({
    mutationFn: async (data) => {
      return apiClient.post(`/feedback/average-rating`, data);
    },
  });
