import React, { useState } from "react";
import { Stack, Typography, Button } from "@mui/material";

const maxLength = 55;

interface IProps {
  text: string;
}

const CommentCell: React.FC<IProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isShortText = text.length <= maxLength;

  const displayedText =
    isExpanded || isShortText ? text : `${text.substring(0, maxLength)}...`;

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <Typography fontSize={"14px"}>{displayedText}</Typography>
      {!isShortText ? (
        <Button
          variant="text"
          onClick={toggleText}
          sx={{
            // padding: 0,
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
          size="small"
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Button>
      ) : null}
    </Stack>
  );
};

export default CommentCell;
