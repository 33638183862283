import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import DeleteIcon from "src/assets/svg-icons/delete.svg";
import AddIcon from "src/assets/svg-icons/add.svg";
import { FormInputs } from "../constants";

const GatesInfo = ({ isNotEqualSpaces }: { isNotEqualSpaces: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const { fields, append, remove, replace } = useFieldArray({
    name: "gates",
    control,
  });

  const addGate = () => {
    append({
      name: "",
    });
  };

  const deleteGate = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    remove(index);
  };

  return (
    <Box
      p={3}
      borderRadius={"10px"}
      border={"1.036px solid #5B5B5B"}
      mb={"53px"}
    >
      {/* Parking Configuration */}
      <Typography fontSize={"20px"} fontWeight={600} mb={2}>
        Parking Configuration
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item sm={4}>
          <Controller
            name={`totalParkingSpaces`}
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                fullWidth
                label="Total Parking Spaces"
                size="small"
                {...field}
                error={!!errors.totalParkingSpaces}
                helperText={errors.totalParkingSpaces?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Controller
            name={`valetParkingSpaces`}
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                fullWidth
                label="Valet Parking Spaces"
                size="small"
                {...field}
                error={!!errors.valetParkingSpaces}
                helperText={errors.valetParkingSpaces?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Controller
            name={`selfParkingSpaces`}
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                fullWidth
                label="Self-Parking Spaces"
                size="small"
                {...field}
                error={!!errors.selfParkingSpaces}
                helperText={errors.selfParkingSpaces?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {isNotEqualSpaces && (
        <Typography mt={2} fontSize={"14px"} fontWeight={500} color="error">
          Valet parking spaces + Self-parking spaces should equal The total
          parking spaces input.
        </Typography>
      )}
      {/* Gates */}
      <Typography fontSize={"20px"} fontWeight={600} my={2}>
        Gates Info
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {fields.map((field, index) => (
          <Grid item sm={4} key={field.id}>
            <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
              <Controller
                name={`gates.${index}.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Gate Name"
                    size="small"
                    {...field}
                    error={errors.gates && !!errors.gates[index]?.name}
                    helperText={
                      errors.gates && errors.gates[index]?.name?.message
                    }
                  />
                )}
              />

              {fields.length > 1 && (
                <IconButton onClick={() => deleteGate(index)}>
                  <img src={DeleteIcon} alt="" />
                </IconButton>
              )}

              {fields.length - 1 === index && (
                <IconButton onClick={addGate}>
                  <img src={AddIcon} alt="" />
                </IconButton>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GatesInfo;
