import { Box, Typography } from "@mui/material";
import ActiveLocationsTable from "./table";
import { useGetLocationsList } from "src/api/locations";

const ActiveLocationsPage = () => {
  // APIS
  const { data, isFetching, refetch } = useGetLocationsList({ type: "active" });

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Active Locations
      </Typography>
      <ActiveLocationsTable
        rowsList={data?.locations || []}
        cities={data?.cities || []}
        services={data?.services || []}
        clients={data?.clients || []}
        isLoading={isFetching}
        refetch={refetch}
      />
    </Box>
  );
};

export default ActiveLocationsPage;
