import { useEffect } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import StarIcon from "src/assets/svg-icons/star.svg";
import { useFetchFeedbackAvgRatingMutation } from "src/api/customers/feedback";
import Bar from "../bar";
import moment from "moment";

interface IProps {
  cities: string[];
  locations: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  rates: string[];
}

const ServiceExperience = (props: IProps) => {
  const { cities, locations, dateRange, rates } = props;

  // APIS
  const { mutate, data, isPending } = useFetchFeedbackAvgRatingMutation();

  useEffect(() => {
    mutate({
      type: "service",
      ...(cities.length > 0 ? { cityIds: cities } : {}),
      ...(locations.length > 0 ? { locationIds: locations } : {}),
      ...(dateRange.startDate && {
        startDate: moment(dateRange.startDate).format("DD/MM/YYYY"),
      }),
      ...(dateRange.endDate && {
        endDate: moment(dateRange.endDate).format("DD/MM/YYYY"),
      }),
      ...(rates.length > 0 ? { ratings: rates.map((rate) => +rate) } : {}),
    });
  }, [cities, locations, dateRange, rates]);

  const valuesArr = Object.values(data?.ratingCounts || {});

  const totalReviews = valuesArr?.reduce(
    (acc: number, value: any) => acc + value,
    0
  );

  const maxValue = Math.max(...(valuesArr as []));

  return (
    <Box
      pt={"20px"}
      pb={"44px"}
      borderRight={"12px"}
      bgcolor={"#242424"}
      boxShadow={" 0px 2.232px 11.161px 0px rgba(0, 0, 0, 0.10)"}
      borderRadius={"12px"}
    >
      <Typography
        fontSize={"20px"}
        fontWeight={700}
        color={"#FFFFFF"}
        lineHeight={"32px"}
        mx={{ xs: "16px", md: "30px" }}
        mb={"20px"}
      >
        Service Experience
      </Typography>
      {/* divider */}
      <Box bgcolor={"#000000"} width={"100%"} height={"2px"} mb="42px" />

      <Stack
        spacing={"10px"}
        direction={"row"}
        alignItems={"center"}
        mx={{ xs: "16px", md: "32px" }}
      >
        <img src={StarIcon} alt="" />
        <Typography
          color={"#D4AD53"}
          fontSize={"50px"}
          lineHeight={"32px"}
          fontWeight={700}
        >
          {data?.averageRating}
        </Typography>
        <Stack alignItems={"center"}>
          <Typography
            fontSize={"18px"}
            lineHeight={"32px"}
            fontWeight={600}
            color={"#FFFFFF"}
          >
            Average Rating
          </Typography>
          <Typography
            fontSize={"14px"}
            lineHeight={"32px"}
            fontWeight={300}
            color={"#FFFFFF"}
          >
            {totalReviews} Total Reviews
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={1} mt={3} mx={{ xs: "16px", md: "32px" }}>
        {isPending ? (
          <>
            {[1, 2, 3, 4, 5].map(() => (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", borderRadius: "12px" }}
              />
            ))}
          </>
        ) : (
          <>
            {Object.entries(data?.ratingCounts || {})
              .filter(
                ([key, value]) =>
                  rates.length === 0 ||
                  (rates.length > 0 && rates.includes(key))
              )
              .reverse()
              .map(([key, value]) => {
                return (
                  <Bar
                    stars={key}
                    percentage={`${((value as number) / maxValue) * 100}`}
                    review={(value as string) || ""}
                  />
                );
              })}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ServiceExperience;
