import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useMemo, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
  TextField,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { functions } from "src/firebase";
import moment from "moment";
import StatusChip from "src/shared/components/status-chip";
import DateRangeInput from "src/shared/components/date-range";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { httpsCallable } from "firebase/functions";
import NoFilterSelected from "../no-filters-selected";
import TransactionsDetailsDrawer from "../transaction-details-popup";
import { downloadFile } from "src/utils";
import { useGetLocationsList } from "src/api/locations";
import { useGetTransactionsList } from "src/api/transactions";

const paymentsTypes = ["card", "cash", "online", "free"];
// const paymentsTypes = [
//   { label: "Card", value: "card" },
//   { label: "Cash", value: "cash" },
//   { label: "Online", value: "online" },
//   { label: "Free", value: "free" },
// ];

const tableHeadText = [
  "Ticket Num",
  "Location Name",
  "Service",
  "Service type",
  "Check-out",
  "Date",
  "Status",
];

const TransactionsTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [endDateFocused, setEndDateFocused] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState<string>("");
  const [location, setLocation] = useState<any | null>(null);
  const [selectedService, setSelectedService] = useState<string>("all");
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState();

  //Callable functions
  const exportTransactionsList = httpsCallable(functions, "exportTransactions");

  const fromDate = moment(dateRange.startDate, "YYYY-MM-DD")
    .startOf("day")
    .valueOf();

  const toDate = moment(dateRange.endDate, "YYYY-MM-DD").endOf("day").valueOf();

  // APIS
  const { data: locationsData } = useGetLocationsList();
  const { data: transactionsData, isFetching } = useGetTransactionsList({
    location: location?.id,
    ...(dateRange.startDate && { from: `${fromDate}` }),
    to: `${toDate}`,
    offset: `${page}`,
    limit: `${rowsPerPage}`,
    ...(searchBy && { search: searchBy }),
    ...(selectedService && { service: `${selectedService}` }),
    ...(selectedPayments.length > 0 && {
      paymentTypes: selectedPayments,
    }),
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (searchedVal: string) => {
    setPage(0);
    setSearchBy(searchedVal);
  };

  const clearSearch = () => {
    if (searchBy !== "") {
      handleSearch("");
    }
  };

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  //Filters
  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };

  const handleChangePayments = (
    event: SelectChangeEvent<typeof paymentsTypes>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedPayments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeLocation = (event: React.SyntheticEvent, newValue: any) => {
    setLocation(newValue);
  };

  const handleExport = () => {
    setIsLoadingExport(true);
    exportTransactionsList({
      ...(!!searchBy && {
        search: searchBy,
      }),
      from: dateRange?.startDate || undefined,
      to: dateRange?.endDate || undefined,
      ...(!!location && {
        locations: [location?.nameEn],
      }),
      ...(selectedPayments.length > 0 && {
        paymentTypes: selectedPayments,
      }),
      ...(selectedService !== "all" && {
        service: selectedService,
      }),
    })
      .then((result: any) => {
        console.log({ result });
        setIsLoadingExport(false);
        const utf8CSVData = `\uFEFF${result?.data.data}`;
        downloadFile(utf8CSVData, "text/csv", "transactions");
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "Downloaded successfully",
          },
        ]);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoadingExport(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setClickedRow(undefined);
  };

  // const emptyDataArr = transactionsList?.length === 0 && !isLoading;
  const emptyDataArr = transactionsData?.data.length === 0;

  useEffect(() => {
    setPage(0);
  }, [location, dateRange, selectedService, selectedPayments, searchBy]);

  useEffect(() => {
    if (!!transactionsData) {
      setTotalCount((transactionsData.totalTransactions as number) ?? 0);
    }
  }, [transactionsData]);

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations.sort((a: any, b: any) =>
        a.nameEn?.localeCompare(b.nameEn)
      ) || []
    );
  }, [locationsData]);

  console.log({ page, location });

  return (
    <>
      <Grid container spacing={1} mb={3}>
        {/* locations */}
        <Grid item xs={12} md={3}>
          <Autocomplete
            size="small"
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={
              sortedLocations.filter(
                (location: any) => location.nameEn || location.name
              ) || []
            }
            getOptionLabel={(option: any) => option.nameEn || location.name}
            value={location}
            onChange={handleChangeLocation}
            renderInput={(params: any) => (
              <TextField fullWidth {...params} label={"Location"} />
            )}
          />
        </Grid>
        {/* date range */}
        <Grid item xs={12} md={6}>
          <Box width={{ xs: "100%", sm: "fit-content" }}>
            <DateRangeInput
              startDate={
                dateRange?.startDate ? moment(dateRange?.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange?.endDate ? moment(dateRange?.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
              // isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <LoadingButton
            variant="contained"
            sx={{ gap: 0.5 }}
            onClick={handleApplyMainFilters}
            // loading={}
          >
            Apply
          </LoadingButton>
        </Grid> */}
      </Grid>
      {/* </Stack> */}
      {!dateRange?.startDate || !dateRange?.endDate || !location ? (
        <NoFilterSelected />
      ) : (
        <Paper
          sx={{
            bgcolor: "#1A1A1A",
            boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
            padding: { xs: "15px", md: "30px" },
          }}
        >
          <Grid container spacing={{ xs: 1.4, sm: 1 }} mb={3}>
            {/* search */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search"
                value={searchBy}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ opacity: !searchBy ? 0 : 1 }}
                    >
                      <IconButton size="small" onClick={clearSearch}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* payment type */}
            <Grid item xs={12} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedPayments}
                  onChange={handleChangePayments}
                  input={<OutlinedInput label="Payment Type" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {paymentsTypes.map((payment) => (
                    <MenuItem key={payment} value={payment}>
                      <Checkbox
                        size="small"
                        checked={selectedPayments.indexOf(payment) > -1}
                      />
                      <ListItemText primary={payment} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Service */}
            <Grid item xs={12} md={2.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Service</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedService}
                  label="Service"
                  onChange={handleChangeServiceType}
                  fullWidth
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"valet"}>Valet</MenuItem>
                  <MenuItem value={"selfParking"}>Self Parking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!emptyDataArr ? (
              <Grid
                item
                xs={12}
                md={3.2}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <LoadingButton
                  variant="contained"
                  sx={{ gap: 0.5 }}
                  onClick={handleExport}
                  loading={isLoadingExport}
                >
                  Export
                </LoadingButton>
              </Grid>
            ) : null}
          </Grid>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeadText.map((item) => (
                    <TableCell
                      key={item}
                      sx={{
                        fontWeight: 600,
                        color: "#A1A5B7",
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isFetching ? (
                <TableLoadingSkeleton
                  rowsLength={rowsPerPage}
                  cellsLength={7}
                />
              ) : (
                <TableBody>
                  {transactionsData?.data?.map((row: any) => {
                    const { paymentStatus } = row;
                    return (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => {
                          setOpen(true);
                          setClickedRow(row);
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>{row.ticketNumber}</TableCell>
                        <TableCell>{row.locationName}</TableCell>
                        <TableCell>{row.service?.name}</TableCell>
                        <TableCell>
                          {row.service?.type === "valet"
                            ? "Valet"
                            : "Self Parking"}
                        </TableCell>
                        <TableCell>
                          <StatusChip
                            color={!!row.checkOutTime ? "success" : "warning"}
                            label={row.checkOutTime ? "Done" : "Not Done"}
                          />
                        </TableCell>
                        <TableCell>
                          {row?.checkInTime || row?.arrivalTime
                            ? moment(
                                row?.checkInTime || row?.arrivalTime
                              ).format("DD/MM/yyyy")
                            : ""}
                        </TableCell>
                        <TableCell>
                          {!!paymentStatus && (
                            <StatusChip
                              color={
                                paymentStatus === "Paid"
                                  ? "success"
                                  : paymentStatus === "Free"
                                  ? "blue"
                                  : paymentStatus === "Refunded"
                                  ? "gray"
                                  : paymentStatus === "Cancelled"
                                  ? "error"
                                  : "warning" //"Pending"
                              }
                              label={paymentStatus}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>

            {emptyDataArr && <NoData />}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          {open && !!clickedRow ? (
            <TransactionsDetailsDrawer
              transDetails={clickedRow}
              open={open}
              onClose={handleCloseDrawer}
            />
          ) : null}
        </Paper>
      )}
    </>
  );
};
export default TransactionsTable;
