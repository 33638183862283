import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetCitiesList, useUpdateCitiesMutation } from "src/api/general";
import { ICity } from "src/types/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import SearchIcon from "@mui/icons-material/Search";

interface IProps {
  open: boolean;
  onClose: Function;
  refetchCities: Function;
}

const ActivateCitiesPopup: React.FC<IProps> = (props) => {
  const { open, onClose, refetchCities } = props;
  const [cities, setCities] = useState<ICity[]>([]);
  const [filteredCities, setFilteredCities] = useState<ICity[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { data } = useGetCitiesList();
  const { mutate, isPending, error, status } = useUpdateCitiesMutation();

  const handleClose = () => {
    onClose();
  };

  const handleSwitchChange = (cityId: string, checked: boolean) => {
    setCities((prevCities) =>
      prevCities.map((city) =>
        city.id === cityId ? { ...city, isActive: checked } : city
      )
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const changedItems: ICity[] = [];
    // Iterate through the arrays using their index
    data?.data.forEach((item, index) => {
      const modifiedItem = cities[index];
      // Compare the isActive value
      if (item?.isActive !== modifiedItem.isActive) {
        changedItems.push(modifiedItem);
      }
    });
    const dataToSend = changedItems.map((city) => {
      return {
        id: city.id,
        isActive: city.isActive,
      };
    });
    mutate(dataToSend);
  };

  useEffect(() => {
    setCities(data?.data || []);
  }, [data]);

  useEffect(() => {
    if (!!searchQuery && cities.length > 0) {
      setFilteredCities(
        cities.filter((city) =>
          city.nameEn?.toLowerCase().includes(searchQuery?.toLowerCase())
        )
      );
    } else {
      setFilteredCities(cities);
    }
  }, [searchQuery, cities]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "Cities have been updated successfully",
        },
      ]);
      onClose();
      refetchCities();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      component={"div"}
    >
      <DialogTitle sx={{ p: "50px 10px 25px 50px" }}>
        <Typography fontSize={"24px"} fontWeight={500}>
          Activate a New City
        </Typography>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <Box px={"50px"}>
          <TextField
            size="small"
            fullWidth
            placeholder="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={1}>
            {filteredCities?.map((city, index) => {
              return (
                <Grid item xs={6} md={4}>
                  <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                    <Switch
                      checked={city.isActive}
                      //   onChange={(e) => {
                      //     setCities((prev) => {
                      //       const updatedCities = [...prev];
                      //       updatedCities[index] = {
                      //         ...updatedCities[index],
                      //         isActive: e.target.checked,
                      //       };
                      //       return updatedCities;
                      //     });
                      //   }}
                      onChange={(e) =>
                        handleSwitchChange(city.id, e.target.checked)
                      }
                    />
                    <Typography fontSize={"14px"} fontWeight={600}>
                      {city.nameEn}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <DialogActions sx={{ padding: "30px 50px 50px 50px" }}>
          <LoadingButton
            loading={isPending}
            sx={{ minWidth: "208px" }}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ActivateCitiesPopup;
